

































































































































































































































































































import { Messages } from "@/models/enums/messages.enum";
import { userServices } from "@/services/user.services";
import {
  RequestPrivilege,
  RequestPrivilegeUpdate,
  ResponseListOfPrivilege,
  DataPrivilege,
} from "@/models/interface/user.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  PropsModel,
  ResponsePagination,
} from "@/models/constant/interface/common.interface";
import { getauthorities } from "@/helpers/cookie";
import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
export default Vue.extend({
  mixins: [MNotificationVue],
  data() {
    return {
      authPrivilege: [] as string[],
      loadingTable: false as boolean,
      totalElements: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      search: "" as string,
      direction: "" as string,
      sort: "" as string,
      idForUpdate: "" as string,
      visibleModalCreate: false as boolean,
      visibleModalUpdate: false as boolean,
      isFormSubmitted: false as boolean,
      loading: {
        saveModal: false as boolean,
        saveCreate: false as boolean,
      },
      switchActiveAndCRUD: {
        createSwitchActive: true,
        createSwitchCreate: true,
        createSwitchRead: true,
        createSwitchUpdate: true,
        createSwitchDelete: true,
        updateSwitchActive: true,
      },
      dataSource: [] as DataPrivilege[],
      columnsTable: [
        {
          title: this.$root.$t("lbl_number_short"),
          dataIndex: "key",
          key: "key",
          // ellipsis: true,
          width: 40,
        },
        {
          title: this.$root.$t("lbl_authority"),
          dataIndex: "authority",
          key: "authority",
          sorter: true,
          // ellipsis: true,
          scopedSlots: { customRender: "isNull" },
          width: 150,
        },
        {
          title: this.$root.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          sorter: true,
          // ellipsis: true,
          scopedSlots: { customRender: "isNull" },
          width: 200,
        },
        {
          title: this.$root.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          // sorter: true,
          // ellipsis: true,
          scopedSlots: { customRender: "isEnable" },
          width: 60,
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
          width: 120,
          align: "center",
          fixed: "right",
        },
      ] as PropsModel[],
      formCreatePrivilege: this.$form.createForm(this, {
        name: "createPrivilege",
      }),
      formRulesCreatePrivilege: {
        authority: {
          label: "lbl_authority",
          name: "authority",
          placeholder: "lbl_authority_placeholder",
          decorator: [
            "authority",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        isActive: {
          label: "lbl_active",
          name: "active",
          decorator: ["active"],
        },
        create: {
          label: "lbl_create",
          name: "create",
        },
        read: {
          label: "lbl_read",
          name: "read",
        },
        update: {
          label: "lbl_edit",
          name: "update",
        },
        delete: {
          label: "lbl_delete",
          name: "delete",
        },
      },
      formUpdatePrivilege: this.$form.createForm(this, {
        name: "updatePrivilege",
      }),
      formRulesUpdatePrivilege: {
        authority: {
          label: "lbl_authority",
          name: "authority",
          placeholder: "lbl_authority_placeholder",
          decorator: [
            "authority",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: ["description"],
        },
        isActive: {
          label: "lbl_active",
          name: "active",
          decorator: ["active"],
        },
      },
    };
  },
  methods: {
    ResetFilter(): void {
      this.direction = "";
      this.search = "";
      this.getListPrivilege(false);
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListPrivilege(true);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListPrivilege(true);
    },
    onSorterChange(response): void {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
      this.getListPrivilege(false);
    },
    getListPrivilege(page) {
      let params = {
        limit: this.limit,
        page: page ? this.page - 1 : 0,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      this.loadingTable = true;
      userServices
        .listOfPrivilege(params)
        .then((res: ResponseListOfPrivilege) => {
          res.data.forEach(
            (dataObject, index) =>
              (dataObject.key =
                (page ? this.page - 1 : 0) * this.limit + index + 1)
          );
          this.dataSource = res.data;
          this.totalElements = res.totalElements;
        })
        .finally(() => (this.loadingTable = false));
    },
    reponseSearchInput(response): void {
      if (response)
        this.search =
          "authority~*" + response + "*_OR_description~*" + response + "*";
      else this.search = "";
      this.getListPrivilege(false);
    },
    showModal() {
      this.visibleModalCreate = true;
    },
    showModalUpdate(response) {
      this.visibleModalUpdate = true;
      this.idForUpdate = response.data.id;
      this.switchActiveAndCRUD.updateSwitchActive = response.data.active;

      this.$nextTick(() => {
        this.formUpdatePrivilege.setFieldsValue({
          description: response.data.description,
          authority: response.data.authority,
        });
      });
    },
    submitForm(e) {
      e.preventDefault();
      this.formCreatePrivilege.validateFields((err, values) => {
        if (err || this.isFormSubmitted) return;
        if (
          !this.switchActiveAndCRUD.createSwitchCreate &&
          !this.switchActiveAndCRUD.createSwitchRead &&
          !this.switchActiveAndCRUD.createSwitchUpdate &&
          !this.switchActiveAndCRUD.createSwitchDelete
        ) {
          this.$notification["error"]({
            message: "Error",
            description: "Select one, create / read / update / delete",
          });
          return;
        }
        const privileges: string[] = [];
        if (this.switchActiveAndCRUD.createSwitchCreate) {
          privileges.push(`${values.authority}:create`);
        }
        // if(this.switchActiveAndCRUD.createSwitchRead) {
        // pasti true
        privileges.push(`${values.authority}:read`);
        // }
        if (this.switchActiveAndCRUD.createSwitchUpdate) {
          privileges.push(`${values.authority}:update`);
        }
        if (this.switchActiveAndCRUD.createSwitchDelete) {
          privileges.push(`${values.authority}:delete`);
        }
        const payload = {
          authority: values.authority,
          description: values.description,
          active: this.switchActiveAndCRUD.createSwitchActive,
          scope: privileges,
        } as RequestPrivilege;
        this.loading.saveCreate = true;
        userServices
          .createPrivilege(payload)
          .then(() => {
            this.visibleModalCreate = false;
            this.showNotifSuccess(Messages.CREATE_SUCCESS);
            this.getListPrivilege(false);
            this.formCreatePrivilege.resetFields();
          })
          .catch(error => {
            this.isFormSubmitted = false;
            this.showNotifError(error?.details);
            this.showNotifError(Messages.CREATE_FAIL);
          })
          .finally(() => (this.loading.saveCreate = false));
      });
    },
    submitFormUpdate(e) {
      e.preventDefault();
      this.formUpdatePrivilege.validateFields((err, values) => {
        if (err || this.isFormSubmitted) return;
        const payload = {
          authority: values.authority,
          description: values.description || "",
          active: this.switchActiveAndCRUD.updateSwitchActive,
        } as RequestPrivilegeUpdate;
        this.loading.saveModal = true;
        userServices
          .updatePrivilege(this.idForUpdate, payload)
          .then(() => {
            this.visibleModalUpdate = false;
            this.showNotifSuccess(Messages.UPDATE_SUCCESS);
            this.getListPrivilege(false);
            this.formUpdatePrivilege.resetFields();
          })
          .catch(error => {
            this.isFormSubmitted = false;
            this.showNotifError(error?.details);
            this.showNotifError(Messages.UPDATE_FAIL);
          })
          .finally(() => (this.loading.saveModal = false));
      });
    },
    handleCancel() {
      this.visibleModalCreate = false;
      this.visibleModalUpdate = false;
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  mounted() {
    this.getListPrivilege(false);
  },
});
